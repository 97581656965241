export const CgvHeader = ({
  headerNum,
  underLinedText,
}: {
  headerNum: number;
  underLinedText: string;
}) => {
  return (
    <div className="flex flex-wrap gap-x-4 font-bold">
      <p>{`Article ${headerNum}`}</p>
      <p className="underline">{underLinedText}</p>
    </div>
  );
};
