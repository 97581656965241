"use client";

import { Modal, ModalProps } from "@costar/theme-ui";

interface PrivatePolicyThemeUIModalProps {
  showPrivatePolicyModal: boolean;
  ariaMessage?: string;
  onClose?: () => void;
  children: ModalProps["children"];
}

export const PrivatePolicyThemeUIModal = ({
  showPrivatePolicyModal,
  onClose,
  ariaMessage,
  children,
}: PrivatePolicyThemeUIModalProps) => {
  return (
    <Modal
      open={showPrivatePolicyModal}
      onClose={onClose}
      aria-labelledby={ariaMessage}
      aria-describedby={ariaMessage}
      zIndex={50}
      BackdropProps={{
        bg: "rgba(0, 0, 0, 0.2)",
      }}
    >
      {children}
    </Modal>
  );
};
